import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ThemeToggle from './ThemeToggle';

const HeaderContainer = styled.header`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Logo = styled(Link)`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
`;

const Nav = styled.nav`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    display: ${props => props.isOpen ? 'flex' : 'none'};
    margin-top: 1rem;
  }
`;

const NavLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0;
  }
`;

const ExternalLink = styled.a`
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0;
  }
`;

const BuyButton = styled.a`
  background-color: yellow;
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: bold;
  margin-right: 1rem;
  
  &:hover {
    background-color: #e6e600;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${props => props.theme.colors.text};
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <HeaderContainer>
      <Logo to="/home">Satoshi Mystery</Logo>
      <MenuButton onClick={() => setIsNavOpen(!isNavOpen)}>☰</MenuButton>
      <Nav isOpen={isNavOpen}>
        <BuyButton href="https://raydium.io" target="_blank" rel="noopener noreferrer">BUY</BuyButton>
        <NavLink to="/home">Home</NavLink>
        <NavLink to="/theories">Discover</NavLink>
        <ExternalLink href="https://t.me/unlockingsatoshi" target="_blank" rel="noopener noreferrer">Telegram</ExternalLink>
        <ExternalLink href="https://x.com/UnlockingSat" target="_blank" rel="noopener noreferrer">Twitter</ExternalLink>
        <ExternalLink href="https://pump.fun" target="_blank" rel="noopener noreferrer">PumpFun</ExternalLink>
        <ThemeToggle />
      </Nav>
    </HeaderContainer>
  );
}

export default Header;