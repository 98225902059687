import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import Header from './Header';
import Footer from './Footer';

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  padding-bottom: 100px;
`;

const Content = styled(animated.main)`
  position: relative;
  z-index: 1;
  padding: 2rem;
  color: ${props => props.theme.colors.text};
  background-color: rgba(0, 0, 0, 0.7);
  margin: 2rem auto;
  max-width: 800px;
  border-radius: 10px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const Section = styled.section`
  margin-top: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.primary};
`;

const SubSection = styled.div`
  margin-top: 1.5rem;
`;

const SubSectionTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.primary};
`;

const List = styled.ul`
  list-style-type: disc;
  margin-left: 1.5rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const theoriesContent = {
  satoshiHada: {
    introduction: `The name Satoshi Nakamoto may be a combination of Toshiaki Tanaka, Naohiko Uramoto and Motoshi Takasu. Together, they constitute Satoshi Nakamoto. Hal Finney was also the first person to receive a Bitcoin transaction. He and Satoshi Nakamoto met at the Crypto '98 conference in Santa Barbara, California in 1998.`,
    keyPoints: [
      "In 2007, a year before Bitcoin's release, Satoshi Hada and his team wrote a conference paper on the possibility of realizing private keys.",
      "These three people are all in the United States, but they are actually Japanese.",
      "IBM CEO predicted three business futures in 2013 (including cryptocurrency).",
      "Evidence suggests Hal Finney and 'Satoshi Nakamoto' attended the 1998 encryption conference together.",
      "Satoshi Hada rarely tweets. He was active in 2012 and 2013, then once in 2017, and recently tweeted after 7 years of inactivity.",
      "Among these three people, only Hada is active now. It's speculated that the HBO team might have found him for their documentary.",
    ],
    evidence: [
      "The three researchers gave a speech on 'The Existence of 3-round Zero-knowledge Protocols' at the Crypto '98 conference.",
      "All three worked at IBM.",
      "Hal Finney's wife Fran mentioned in an interview that Hal had communicated with a person who called himself Satoshi and may have lived in Japan.",
      "YouTube video 'The Bitcoin Layer' shows that Satoshi's working hours are consistent with the time zone of Japan.",
      "In 2007, Hada and his team published a paper titled 'A Note on the (Im)possibility of Using Obfuscators to Transform Private-Key Encryption into Public-Key Encryption'.",
    ]
  },
  lenSassaman: {
    introduction: `Len Sassaman was a renowned cryptographer and passionate privacy advocate. He was actively involved with the cypherpunk movement in San Francisco and contributed to privacy-focused projects like Pretty Good Privacy (PGP) and GNU Privacy Guard (GPG).`,
    biography: [
      "Len Sassaman was a doctoral student in electrical engineering at KU Leuven in Belgium.",
      "He tragically passed away by suicide in 2011 at the age of 31.",
      "A tribute to him was encoded into the Bitcoin blockchain, honoring him as 'a friend, a kind soul, and a devious schemer.'",
    ],
    evidence: [
      "Sassaman had an exceptional academic background and expertise in cryptography.",
      "Linguistic analysis shows similarities between his writing style and Nakamoto's.",
      "Sassaman was deeply embedded in the cypherpunk community, closely linked to Bitcoin's early development.",
      "The timing of Sassaman's death in 2011 aligns with Nakamoto's disappearance from the public eye.",
      "Satoshi Nakamoto went silent just two months before Len Sassaman's death.",
      "Sassaman's suicide note contained 24 random words, resembling the 24-word seed phrases used to secure cryptocurrency wallets.",
      "$64 billion worth of Satoshi's Bitcoin ($BTC) still remains untouched to this day.",
    ],
    counterArguments: [
      "Sassaman's wife firmly denies the theory, stating: 'Len was many things, but he was not Satoshi. He would never have left me in the dark on something like that.'",
    ],
    mediaReference: "HBO produced a documentary titled 'MoneyElectric: The Bitcoin Mystery,' hinting at the possibility that Len Sassaman might have been Satoshi Nakamoto.",
    conclusion: "Regardless of whether Len Sassaman was Satoshi Nakamoto, his contributions to cryptography and privacy remain significant."
  }
};

function TheoriesPage() {
  const contentProps = useSpring({
    from: { opacity: 0, transform: 'translateY(50px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 300,
  });

  return (
    <PageContainer>
      <Header />
      <Content style={contentProps}>
        <Title>Theories on Satoshi's Identity</Title>
        
        <Section>
          <SectionTitle>Satoshi Hada Theory</SectionTitle>
          <p>{theoriesContent.satoshiHada.introduction}</p>
          <SubSection>
            <SubSectionTitle>Key Points</SubSectionTitle>
            <List>
              {theoriesContent.satoshiHada.keyPoints.map((point, index) => (
                <ListItem key={index}>{point}</ListItem>
              ))}
            </List>
          </SubSection>
          <SubSection>
            <SubSectionTitle>Evidence</SubSectionTitle>
            <List>
              {theoriesContent.satoshiHada.evidence.map((evidence, index) => (
                <ListItem key={index}>{evidence}</ListItem>
              ))}
            </List>
          </SubSection>
        </Section>

        <Section>
          <SectionTitle>Len Sassaman Theory</SectionTitle>
          <p>{theoriesContent.lenSassaman.introduction}</p>
          <SubSection>
            <SubSectionTitle>Biography</SubSectionTitle>
            <List>
              {theoriesContent.lenSassaman.biography.map((bio, index) => (
                <ListItem key={index}>{bio}</ListItem>
              ))}
            </List>
          </SubSection>
          <SubSection>
            <SubSectionTitle>Evidence</SubSectionTitle>
            <List>
              {theoriesContent.lenSassaman.evidence.map((evidence, index) => (
                <ListItem key={index}>{evidence}</ListItem>
              ))}
            </List>
          </SubSection>
          <SubSection>
            <SubSectionTitle>Counter Arguments</SubSectionTitle>
            <List>
              {theoriesContent.lenSassaman.counterArguments.map((arg, index) => (
                <ListItem key={index}>{arg}</ListItem>
              ))}
            </List>
          </SubSection>
          <p><strong>Media Reference:</strong> {theoriesContent.lenSassaman.mediaReference}</p>
          <p><strong>Conclusion:</strong> {theoriesContent.lenSassaman.conclusion}</p>
        </Section>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default TheoriesPage;