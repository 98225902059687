import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const PageContainer = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
`;

const Content = styled.main`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const ResourceSection = styled.section`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const ResourceList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ResourceItem = styled.li`
  margin-bottom: 1rem;
`;

const ResourceLink = styled.a`
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  font-weight: bold;
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const ResourceDescription = styled.p`
  margin-top: 0.5rem;
  font-size: 0.9rem;
`;

function ResourcesPage() {
  return (
    <PageContainer>
      <Header />
      <Content>
        <Title>Resources</Title>
        
        <ResourceSection>
          <SectionTitle>Academic Papers</SectionTitle>
          <ResourceList>
            <ResourceItem>
              <ResourceLink href="https://bitcoin.org/bitcoin.pdf" target="_blank" rel="noopener noreferrer">
                Bitcoin: A Peer-to-Peer Electronic Cash System
              </ResourceLink>
              <ResourceDescription>
                The original Bitcoin whitepaper by Satoshi Nakamoto, outlining the fundamental concepts of the cryptocurrency.
              </ResourceDescription>
            </ResourceItem>
            <ResourceItem>
              <ResourceLink href="#" target="_blank" rel="noopener noreferrer">
                The Age of Cryptocurrency: How Bitcoin and Digital Money Are Challenging the Global Economic Order
              </ResourceLink>
              <ResourceDescription>
                A comprehensive study on the impact of cryptocurrencies on the global economy, including insights into Bitcoin's origins.
              </ResourceDescription>
            </ResourceItem>
          </ResourceList>
        </ResourceSection>

        <ResourceSection>
          <SectionTitle>Articles and Investigations</SectionTitle>
          <ResourceList>
            <ResourceItem>
              <ResourceLink href="https://www.newyorker.com/tech/annals-of-technology/the-incredible-rise-of-bitcoin" target="_blank" rel="noopener noreferrer">
                The Incredible Rise of Bitcoin
              </ResourceLink>
              <ResourceDescription>
                A New Yorker article detailing the early days of Bitcoin and the mystery surrounding its creator.
              </ResourceDescription>
            </ResourceItem>
            <ResourceItem>
              <ResourceLink href="#" target="_blank" rel="noopener noreferrer">
                The Hunt for Satoshi Nakamoto: A Look at the Top Candidates
              </ResourceLink>
              <ResourceDescription>
                An in-depth analysis of various theories about Satoshi Nakamoto's identity, including evidence for and against each candidate.
              </ResourceDescription>
            </ResourceItem>
          </ResourceList>
        </ResourceSection>

        <ResourceSection>
          <SectionTitle>Primary Sources</SectionTitle>
          <ResourceList>
            <ResourceItem>
              <ResourceLink href="https://satoshi.nakamotoinstitute.org/" target="_blank" rel="noopener noreferrer">
                The Complete Satoshi
              </ResourceLink>
              <ResourceDescription>
                A collection of all known emails, forum posts, and other writings by Satoshi Nakamoto.
              </ResourceDescription>
            </ResourceItem>
            <ResourceItem>
              <ResourceLink href="https://github.com/bitcoin/bitcoin" target="_blank" rel="noopener noreferrer">
                Original Bitcoin Core Repository
              </ResourceLink>
              <ResourceDescription>
                The GitHub repository containing the original Bitcoin source code and its evolution over time.
              </ResourceDescription>
            </ResourceItem>
          </ResourceList>
        </ResourceSection>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default ResourcesPage;