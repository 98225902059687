import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const LandingContainer = styled(animated.div)`
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/your-landing-page.gif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
`;

const Title = styled(animated.h1)`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: white;
`;

const EnterButton = styled(animated.button)`
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.colors.primaryHover};
  }
`;

function LandingPage() {
  const navigate = useNavigate();

  const contentProps = useSpring({
    from: { opacity: 0, transform: 'translateY(50px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 500,
    config: { tension: 300, friction: 10 }
  });

  const handleEnter = () => {
    navigate('/home');
  };

  return (
    <LandingContainer>
      <BackgroundImage />
      <ContentWrapper style={contentProps}>
        <Title>Unraveling the Satoshi Mystery</Title>
        <EnterButton onClick={handleEnter}>Enter</EnterButton>
      </ContentWrapper>
    </LandingContainer>
  );
}

export default LandingPage;