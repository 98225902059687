import React, { useState } from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const PageContainer = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
`;

const Content = styled.main`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const SocialLink = styled.a`
  display: inline-block;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  
  &:hover {
    background-color: ${props => props.theme.colors.primaryHover};
  }
`;

const CommentForm = styled.form`
  margin-bottom: 2rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
`;

const SubmitButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: ${props => props.theme.colors.primaryHover};
  }
`;

const CommentList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CommentItem = styled.li`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
`;

function CommunityPage() {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newComment.trim()) {
      setComments([...comments, newComment]);
      setNewComment('');
    }
  };

  return (
    <PageContainer>
      <Header />
      <Content>
        <Title>Community</Title>
        
        <Section>
          <SectionTitle>Join the Conversation</SectionTitle>
          <p>Connect with other enthusiasts and stay updated on the latest Satoshi theories:</p>
          <SocialLink href="https://t.me/satoshimystery" target="_blank" rel="noopener noreferrer">Telegram</SocialLink>
          <SocialLink href="https://twitter.com/satoshimystery" target="_blank" rel="noopener noreferrer">Twitter</SocialLink>
        </Section>

        <Section>
          <SectionTitle>Discussion Forum</SectionTitle>
          <CommentForm onSubmit={handleSubmit}>
            <TextArea 
              rows="4" 
              placeholder="Share your thoughts on the Satoshi mystery..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
            <SubmitButton type="submit">Post Comment</SubmitButton>
          </CommentForm>

          <CommentList>
            {comments.map((comment, index) => (
              <CommentItem key={index}>{comment}</CommentItem>
            ))}
          </CommentList>
        </Section>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default CommunityPage;