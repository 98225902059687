import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useTheme } from '../contexts/ThemeContext';


const ToggleContainer = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  font-size: 0.5rem;
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
  padding: 0.5rem;
  position: relative;
  width: 8rem;
  height: 4rem;
`;

const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  svg {
    height: auto;
    width: 2.5rem;
    transition: all 0.3s linear;
    
    &:first-child {
      transform: ${({ isDarkMode }) => isDarkMode ? 'translateY(0)' : 'translateY(100px)'};
    }
    
    &:nth-child(2) {
      transform: ${({ isDarkMode }) => isDarkMode ? 'translateY(-100px)' : 'translateY(0)'};
    }
  }
`;

const ToggleButton = styled(animated.div)`
  background: ${({ theme }) => theme.colors.background};
  border-radius: 50%;
  height: 3.5rem;
  left: 0.25rem;
  position: absolute;
  top: 0.25rem;
  transition: all 0.2s linear;
  width: 3.5rem;
`;

const ThemeToggle = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  const properties = {
    dark: {
      r: 9,
      transform: "rotate(40deg)",
      cx: 12,
      cy: 4,
      opacity: 0
    },
    light: {
      r: 5,
      transform: "rotate(90deg)",
      cx: 30,
      cy: 0,
      opacity: 1
    },
    springConfig: { mass: 4, tension: 250, friction: 35 }
  };

  const { r, transform, cx, cy, opacity } = isDarkMode
    ? properties.dark
    : properties.light;

  const svgContainerProps = useSpring({
    transform,
    config: properties.springConfig
  });
  const centerCircleProps = useSpring({
    r,
    config: properties.springConfig
  });
  const maskedCircleProps = useSpring({
    cx,
    cy,
    config: properties.springConfig
  });
  const linesProps = useSpring({
    opacity,
    config: properties.springConfig
  });

  const toggleButtonProps = useSpring({
    x: isDarkMode ? 0 : 64,
    config: properties.springConfig
  });

  return (
    <ToggleContainer onClick={toggleTheme}>
      <Icons isDarkMode={isDarkMode}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        >
          <circle cx="12" cy="12" r="5" />
          <line x1="12" y1="1" x2="12" y2="3" />
          <line x1="12" y1="21" x2="12" y2="23" />
          <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
          <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
          <line x1="1" y1="12" x2="3" y2="12" />
          <line x1="21" y1="12" x2="23" y2="12" />
          <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
          <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
        </svg>
      </Icons>
      <ToggleButton style={{ transform: toggleButtonProps.x.interpolate(x => `translateX(${x}px)`) }} />
    </ToggleContainer>
  );
};

export default ThemeToggle;