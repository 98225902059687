import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: rgba(0, 0, 0, 0.7);
  color: ${props => props.theme.colors.text};
  padding: 1rem 2rem;
  text-align: center;
  font-size: 0.9rem;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const Disclaimer = styled.p`
  margin-bottom: 0.5rem;
`;

const Copyright = styled.p`
  margin-top: 0.5rem;
`;

function Footer() {
  return (
    <FooterContainer>
      <Disclaimer>
        Disclaimer: This website presents theories and speculations about Satoshi Nakamoto's identity. 
        All information should be considered as unverified and subject to debate.
      </Disclaimer>
      <Copyright>
        © {new Date().getFullYear()} Satoshi Mystery. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
}

export default Footer;