import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import Header from './Header';
import Footer from './Footer';

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  padding-bottom: 100px;
`;

const BackgroundGIF = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/your-home-page.gif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
`;

const Content = styled(animated.main)`
  position: relative;
  z-index: 1;
  padding: 2rem;
  color: ${props => props.theme.colors.text};
  background-color: rgba(0, 0, 0, 0.7);
  margin: 2rem auto;
  max-width: 800px;
  border-radius: 10px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const Section = styled.section`
  margin-top: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.primary};
`;

const flash = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const DiscoverButton = styled(Link)`
  display: block;
  width: 200px;
  margin: 2rem auto;
  padding: 1rem;
  background-color: orange;
  color: black;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  animation: ${flash} 2s infinite;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ffa500;
    animation: none;
  }
`;

function MainPage() {
  const contentProps = useSpring({
    from: { opacity: 0, transform: 'translateY(50px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 300,
  });

  return (
    <PageContainer>
      <BackgroundGIF />
      <Header />
      <Content style={contentProps}>
        <Title>Unraveling the Satoshi Mystery</Title>
        <Section>
          <SectionTitle>The Satoshi Enigma</SectionTitle>
          <p>
            Satoshi Nakamoto, the pseudonymous creator of Bitcoin, has remained one of the biggest 
            mysteries in the cryptocurrency world. This website explores the various theories 
            surrounding Satoshi's identity, with a focus on two prominent candidates: Len Sassaman 
            and Satoshi Hada.
          </p>
        </Section>
        <Section>
          <SectionTitle>Featured Theories</SectionTitle>
          <p>
            Dive into our detailed analysis of the Len Sassaman and Satoshi Hada theories, 
            examining the evidence, timelines, and expert opinions surrounding these potential 
            Bitcoin creators.
          </p>
        </Section>
        <Section>
          <SectionTitle>One Token To Take Over</SectionTitle>
          <p>
            Unlocking Satoshi $USAT will be the token to dominate all other Satoshi tokens. Why?
            We provide all the new and relevant information regarding Satoshi's true identity, without
            taking sides, making us ahead of the curve when new info gets released.
          </p>
        </Section>
        <Section>
          <SectionTitle>CA</SectionTitle>
          <p>
          GnZmeiHb4Bmnh7wa9GDqXEP5AnZFFrpKCuGrpisMpump
          </p>
        </Section>
        <DiscoverButton to="/theories">DISCOVER SATOSHI</DiscoverButton>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default MainPage;