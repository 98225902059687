import React, { createContext, useState, useContext } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const theme = {
    colors: isDarkMode
      ? {
          background: '#1a1a1a',
          text: '#ffffff',
          primary: '#f7931a',
          primaryHover: '#e87b0c',
        }
      : {
          background: '#ffffff',
          text: '#1a1a1a',
          primary: '#f7931a',
          primaryHover: '#e87b0c',
        },
  };

  return (
    <ThemeContext.Provider value={{ theme, isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);